<template>
  <div>
    <b-modal id="showClientData" size="lg" :title="$t('veterinary.inactive_doctor')" style="direction: rtl" centered hide-footer hide-header-close>
      <b-row>
        <b-col md="6" class="mb-3">
          <span class="title">الاسم</span>
          <span class="res font-weight-bold">{{ userInfo.full_name }}</span>
        </b-col>
        <b-col md="6" class="mb-3">
          <span class="title">البريد الالكترونى</span>
          <span class="res font-weight-bold">{{ userInfo.email }}</span>
        </b-col>
        <b-col md="6" class="mb-3">
          <span class="title">رقم الهاتف</span>
          <span class="res font-weight-bold">{{ userInfo.primary_phone_number }}</span>
        </b-col>
        <b-col md="6" class="mb-3">
          <span class="title">رقم الهاتف الثانوى</span>
          <span class="res font-weight-bold">{{ userInfo.secondary_phone_number }}</span>
        </b-col>
      </b-row>
      <b-row v-if="userInfo && userInfo.uploaded_documents.length">
        <b-col  lg="6" class="mb-4" v-for="item in userInfo.uploaded_documents" :key="item.id">
          <div class="img-upload-box">
            <h4 class="img-upload-box-label">{{item.doc.title}}</h4>
            <!-- box  -->
            <div class="img-box">
              <img :src="item.url" alt="img" class="img-fluid" />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" class="d-flex justify-content-center mt-4">
          <b-button variant="primary" class="vete-save-btn m-auto" v-if="!loading" @click="acceptDoctor(userInfo.id)"> قبول </b-button>
          <b-button variant="primary" class="vete-save-btn m-auto" v-else> <spinner-loading :text="'يتم التحميل'"></spinner-loading> </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <dashboard-page-title  :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">الأطباء الغير مفعلين</dashboard-page-title>
    <main-table :fields="fields" list_url="admin/doctors" :reloadTable="reloadTable" :additional-url="`&status=processing`" :baseURL="VUE_APP_VETE_LINK"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
import api from '../services/index'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      loading: false,
      userInfo: '',
      reloadTable: false,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'الاسم', key: 'full_name', class: 'text-right' },
        { label: 'البريد الإلكترونى', key: 'email', class: 'text-right' },
        { label: 'رقم التليفون', key: 'primary_phone_number', class: 'text-right' },
        { label: 'تاريخ الطلب', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          type: 'actions',
          class: 'text-right',
          actions: [
            {
              text: 'show',
              icon: 'las la-eye',
              color: 'info',
              ifNavigate: true,
              routePath: 'show-inactive-doctors/:id'
            }
          ]
        }
      ]
    }
  },
  methods: {
    showData (data) {
      this.userInfo = data
      this.$bvModal.show('showClientData')
    },
    acceptDocumnets (id) {
      api.acceptDoctorDocumnets(id).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('showClientData')
        this.reloadTable = true
      })
    },
    acceptDoctor (id) {
      this.loading = true
      api.acceptDoctor(id).then(res => {
        core.showSnackbar('success', res.data.message)
        this.acceptDocumnets(id)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.$root.$on('showData', this.showData)
  }
}
</script>

<style lang="scss" scoped>
.title {
  min-width: 150px;
  display: inline-block;
}
.title, .res {
  color: #333
}
</style>
